@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap');


html,body,#root{
  width: 100%;  height: calc(var(--vh, 1vh) * 100);
  overflow: auto;
}

*{
  box-sizing: border-box;
	text-decoration: none;
  margin: 0px;

  /*드래그 금지*/
  -ms-user-select: none; 
  -moz-user-select: -moz-none; 
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

/*선택 제거*/
*::selection {
  background-color:  rgba( 255, 255, 255, 0 );;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,'Inter', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:#FFFFFF;
  font-weight: 400;  font-size: 18px;  line-height: 28px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center{
  display: flex;  justify-content: center;  align-items: center;
}

image{
  width: 100%;
  height: 100%;
}

a:link { color: inherit; text-decoration: none;}
a:visited { color: inherit; text-decoration: none;}
a:hover { color: inherit; text-decoration: underline;}

.H1font{
  font-weight: 500; font-size: 200px; line-height: 1; letter-spacing: -5px;
}

.H2font{
  font-weight: 500; font-size: 56px; line-height: 68px; 
}

.H3font{
  font-weight: 500; font-size: 24px; line-height: 28px; letter-spacing: 4.8px; text-transform: uppercase;
}

.H4font{
  font-weight: 400; font-size: 20px; line-height: 28px; letter-spacing: 4px;
}

.H5font{
  font-weight: 500; font-size: 18px; line-height: 28px;
}

.H6font{
  font-weight: 500; font-size: 15px; line-height: 28px; letter-spacing: 3px;
}
